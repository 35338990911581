<template>
  <div>
    <div class="columns is-multiline is-centered">
      <div class="column is-2 has-text-centered">
        <b-icon pack="fas"
                icon="table"
                size="is-large"></b-icon>
        <div>Alapadatok</div>
      </div>
      <div class="column is-2 has-text-centered">
        <b-icon pack="fas"
                icon="file-alt"
                size="is-large"></b-icon>
        <div>Nyilatkozat 1</div>
      </div>
      <div class="column is-2 has-text-centered">
        <b-icon pack="fas"
                icon="file-alt"
                size="is-large"></b-icon>
        <div>Nyilatkozat 2</div>
      </div>
      <div class="column is-2 has-text-centered">
        <b-icon pack="fas"
                icon="tasks"
                size="is-large"></b-icon>
        <div>Pénzmosás kérdőív</div>
      </div>
      <div class="column is-2 has-text-centered">
        <b-icon pack="fas"
                icon="tasks"
                size="is-large"></b-icon>
        <div>MER egyéni</div>
      </div>
      <div class="column is-2 has-text-centered">
        <b-icon pack="fas"
                icon="file-alt"
                size="is-large"></b-icon>
        <div>Jegyzőkönyv - pénzmosás</div>
      </div>
      <div class="column is-2 has-text-centered">
        <b-icon pack="fas"
                icon="file-alt"
                size="is-large"></b-icon>
        <div>Jegyzőkönyv - MER</div>
      </div>
      <div class="column is-2 has-text-centered">
        <b-icon pack="fas"
                icon="file-alt"
                size="is-large"></b-icon>
        <div>Dokumentumjegyzék</div>
      </div>
      <div class="column is-2 has-text-centered">
        <b-icon pack="fas"
                icon="stream"
                size="is-large"></b-icon>
        <div>Összesítő</div>
      </div>
      <div class="column is-2 has-text-centered">
        <b-icon pack="fas"
                icon="file-alt"
                size="is-large"></b-icon>
        <div>Zárójelentés - MER</div>
      </div>
    </div>
    <!--    TODO: Megbeszél petivel -->
    <div>
      Ötlet, menü balra inkább? vagy csoportosítani, például kérdőívek és azon belül a kérdőívek,
      nyilatkozatok, stb...
    </div>
    <br>
    <br>
    <br>
    <b-menu style="width: 300px">
      <b-menu-list label="XY ellenőrzése">
        <b-menu-item icon="table"
                     label="Alapadatok"></b-menu-item>
        <b-menu-item icon="file-alt"
                     :active="isActive"
                     expanded>
          <template #label="props">
            Nyilatkozatok
            <b-icon class="is-pulled-right"
                    :icon="props.expanded ? 'caret-up' : 'caret-down'"></b-icon>
          </template>
          <b-menu-item icon="file-alt"
                       label="Nyilatkozat 1"></b-menu-item>
          <b-menu-item icon="file-alt"
                       label="Nyilatkozat 2"></b-menu-item>
          <b-menu-item icon="exclamation">
            <template #label>
              Test actions
              <b-dropdown aria-role="list"
                          class="is-pulled-right"
                          position="is-bottom-left">
                <template #trigger>
                  <b-icon icon="ellipsis-v"></b-icon>
                </template>
                <b-dropdown-item aria-role="listitem">Action</b-dropdown-item>
                <b-dropdown-item aria-role="listitem">Another action</b-dropdown-item>
                <b-dropdown-item aria-role="listitem">Something else</b-dropdown-item>
              </b-dropdown>
            </template>
          </b-menu-item>
        </b-menu-item>
        <b-menu-item icon="tasks"
                     label="Kérdőívek">
          <b-menu-item label="Pénzmosás kérdőív"></b-menu-item>
          <b-menu-item label="MER egyéni"></b-menu-item>
        </b-menu-item>
      </b-menu-list>
      <b-menu-list label="Actions (ide kerülhetnének ? vagy e nélkül...)">
        <b-menu-item label="Nyomtatás"></b-menu-item>
        <b-menu-item label="Lezárás"></b-menu-item>
        <b-menu-item label="Feloldás"></b-menu-item>
        <b-menu-item label="Beküldés"></b-menu-item>
      </b-menu-list>
    </b-menu>
  </div>
</template>

<script>
export default {
  name: 'Test2',
  data () {
    return {
      isActive: true,
    }
  },
}
</script>

<style scoped>
</style>
